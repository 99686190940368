// src/services/api.ts
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../redux/store";
import { UserState } from "../redux/types/user";
import { apiHostname } from "../config/api";
import { auth } from "../config/firebase";
import { deleteUser } from "firebase/auth";

interface Branch {
  name: string;
}

interface createUserProp {
  data?: UserState | {};
  headers?: any;
}

export interface UserResponse {
  data: any;
  message: string;
}

interface HomeRoomParams {
  homeId?: string | null;
  roomId?: string | null;
}

const baseQuery = fetchBaseQuery({
  baseUrl: apiHostname,
  // Common headers for every request
  prepareHeaders: async (headers, { getState }) => {
    const state = getState() as RootState; // Assuming you have a slice named 'auth' with a 'token' field
    // const token = state.user.token;
    const token = await auth.currentUser?.getIdToken();
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    headers.set("Content-Type", "application/json");
    // headers.set("X-API-Key", "secretKey");
    return headers;
  },
});

export const api = createApi({
  baseQuery,
  endpoints: (builder) => ({
    getUser: builder.query({
      query: ({ id, headers }) => ({
        url: `users/${id}`,
        method: "GET",
      }),
    }),
    getMembers: builder.query({
      query: () => ({
        url: `users/`,
        method: "GET",
      }),
    }),

    createUser: builder.mutation({
      query: ({ data, headers }) => ({
        url: "users",
        method: "POST",
        body: data,
      }),
    }),

    updateUser: builder.mutation({
      query: ({ data, id }) => ({
        url: `users/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),

    deleteUser: builder.mutation({
      query: ({ id }: { id: string }) => ({
        url: `users/${id}`,
        method: "DELETE",
      }),
    }),
    changeRole: builder.mutation({
      query: ({ id, role }: { id: string; role: string }) => ({
        url: `users/${id}/role`,
        method: "PUT",
        body: { role },
      }),
    }),

    uploadProfilePicture: builder.mutation({
      query: ({ data, userId }: { userId: string; data: any }) => ({
        url: `users/${userId}/profile-picture`,
        method: "PUT",
        body: data,
      }),
    }),

    // Org
    createOrg: builder.mutation({
      query: ({ data, headers }) => ({
        url: "organisations",
        method: "POST",
        body: data,
      }),
    }),
    getOrgs: builder.query({
      query: () => ({
        url: "admin/organisations",
        method: "GET",
      }),
    }),
    orgJoinRequest: builder.mutation({
      query: ({ orgId }: { orgId: string }) => ({
        url: `organisations/${orgId}/request-join`,
        method: "POST",
      }),
    }),
    orgJoinRequestAccept: builder.mutation({
      query: ({
        orgId,
        userId,
        role,
      }: {
        orgId: string;
        userId: string;
        role: string;
      }) => ({
        url: `organisations/${orgId}/accept-join/${userId}`,
        method: "POST",
        body: { role },
      }),
    }),
    orgJoinRequestReject: builder.mutation({
      query: ({ orgId, userId }: { orgId: string; userId: string }) => ({
        url: `organisations/${orgId}/reject-join/${userId}`,
        method: "POST",
      }),
    }),

    leaveOrganisation: builder.mutation({
      query: ({ orgId, userId }: { orgId: string; userId: string }) => ({
        url: `organisations/${orgId}/users/${userId}`,
        method: "DELETE",
      }),
    }),

    removeUserFromOrganisation: builder.mutation({
      query: ({ orgId, userId }: { orgId: string; userId: string }) => ({
        url: `organisations/${orgId}/users/${userId}`,
        method: "DELETE",
      }),
    }),

    // BULBS

    getAllBulbs: builder.query({
      query: (orgId: string) => ({
        url: `bulbs?orgId=${orgId}`,
        method: "GET",
      }),
    }),
    getBulb: builder.query({
      query: ({ id }: { id: string }) => ({
        url: `bulbs/${id}/admin`,
        method: "GET",
      }),
    }),

    getAllUnassignedBulbs: builder.query({
      query: ({ homeId, roomId }: HomeRoomParams) => ({
        url: `bulbs?homeId=${homeId}&roomId=${roomId}`,
        method: "GET",
      }),
    }),
    // Homes

    getAllHomes: builder.query({
      query: () => ({
        url: `homes`,
        method: "GET",
      }),
    }),
    getHomesByOrg: builder.query({
      query: ({ orgId }: { orgId: string }) => ({
        url: `organisations/${orgId}/homes`,
        method: "GET",
      }),
    }),
    checkBulbStatusInOrg: builder.query({
      query: ({ orgId }: { orgId: string }) => ({
        url: `organisations/${orgId}/bulb-status`,
        method: "GET",
      }),
    }),
    createHome: builder.mutation({
      query: ({ orgId, data }) => ({
        url: `homes?orgId=${orgId}`,
        method: "POST",
        body: data,
      }),
    }),
    updateHome: builder.mutation({
      query: ({ id, data }: { id: string; data: any }) => ({
        url: `homes/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteHome: builder.mutation({
      query: ({ id }: { id: string }) => ({
        url: `homes/${id}`,
        method: "DELETE",
      }),
    }),

    // Rooms

    createRoom: builder.mutation({
      query: ({ homeId, data }) => ({
        url: `homes/${homeId}/rooms`,
        method: "POST",
        body: data,
      }),
    }),
    updateRoom: builder.mutation({
      query: ({
        homeId,
        roomId,
        data,
      }: {
        homeId: string;
        roomId: string;
        data: any;
      }) => ({
        url: `homes/${homeId}/rooms/${roomId}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteRoom: builder.mutation({
      query: ({ homeId, roomId }: { homeId: string; roomId: string }) => ({
        url: `homes/${homeId}/rooms/${roomId}`,
        method: "DELETE",
      }),
    }),

    // Bulbs

    uploadFirmware: builder.mutation({
      query: ({ data }) => ({
        url: `bulbs/upload-firmware`,
        method: "POST",
        body: data,
      }),
    }),

    createBulb: builder.mutation({
      query: ({ orgId, data }) => ({
        url: `bulbs?org-id=${orgId}`,
        method: "POST",
        body: data,
      }),
    }),

    assignBulbs: builder.mutation({
      query: ({
        homeId,
        data,
        roomId,
      }: {
        homeId: string;
        data: any;
        roomId: string;
      }) => ({
        url: `homes/${homeId}/rooms/${roomId}/bulbs`,
        method: "POST",
        body: data,
      }),
    }),
    getBulbsbyRoom: builder.query({
      query: ({ homeId, roomId }: { homeId: string; roomId: string }) => ({
        url: `homes/${homeId}/rooms/${roomId}/bulbs`,
        method: "GET",
      }),
    }),

    getBulbsbyHome: builder.query({
      query: ({ homeId }: { homeId: string }) => ({
        url: `homes/${homeId}/bulbs`,
        method: "GET",
      }),
    }),
    getBulbs: builder.query({
      query: () => ({
        url: `bulbs`,
        method: "GET",
      }),
    }),

    removeBulb: builder.mutation({
      query: ({
        homeId,
        roomId,
        bulbId,
      }: {
        homeId: string;
        bulbId: string;
        roomId: string;
      }) => ({
        url: `homes/${homeId}/rooms/${roomId}/bulbs/${bulbId}`,
        method: "DELETE",
      }),
    }),

    updateMasterBulb: builder.mutation({
      query: ({
        homeId,
        roomId,
        bulbId,
      }: {
        homeId: string;
        bulbId: string;
        roomId: string;
      }) => ({
        url: `homes/${homeId}/rooms/${roomId}/bulbs/${bulbId}/master`,
        method: "POST",
      }),
    }),

    getBulbLiveStatus: builder.query({
      query: ({ bulbId }: { bulbId: string }) => ({
        url: `bulbs/${bulbId}`,
        method: "GET",
      }),
    }),

    getBulbDataWithRequestCount: builder.query({
      query: ({ bulbId }: { bulbId: string }) => ({
        url: `bulbs/${bulbId}/admin`,
        method: "GET",
      }),
    }),

    // Data

    getLiveData: builder.query({
      query: ({
        dataType,
        bulbId,
      }: {
        bulbId: string;
        dataType: "temperature" | "humidity" | "movement" | "snore" | "cough";
      }) => ({
        url: `data/live/${bulbId}?data-type=${dataType}`,
        method: "GET",
      }),
    }),

    // Notifiaction
    resolveNotification: builder.mutation({
      query: ({ notificationId }: { notificationId: string }) => ({
        url: `notifications/historic/${notificationId}/resolve`,
        method: "PATCH",
      }),
    }),
    uploadFCMToken: builder.mutation({
      query: ({ fcmToken, userId }: { fcmToken: string; userId: string }) => ({
        url: `users/${userId}/fcm-token`,
        method: "PUT",
        body: { fcmToken },
      }),
    }),
    deleteFCMToken: builder.mutation({
      query: ({ userId, fcmToken }: { userId: string; fcmToken: string }) => ({
        url: `users/${userId}/fcm-token/single`,
        method: "DELETE",
        body: { fcmToken },
      }),
    }),
    getPaginatedNotifications: builder.query({
      query: ({
        userId,
        start,
        type,
      }: {
        userId: string;
        start: string | number;
        type?: "bulbFault" | "joinOrgRequest";
      }) => ({
        url: `notifications/historic/${userId}?limit=${
          process.env.REACT_APP_PAGINATION_LIMIT || 10
        }&start=${start}&type=${type || "bulbFault"}`,
        method: "GET",
      }),
    }),
    getPaginatedNotificationsByBulbId: builder.query({
      query: ({
        userId,
        start,
        type,
        dataSourceId,
      }: {
        userId: string;
        start: string | number;
        type?: "bulbFault" | "joinOrgRequest";
        dataSourceId?: string;
      }) => ({
        url: `notifications/historic/${userId}?limit=${
          process.env.REACT_APP_PAGINATION_LIMIT || 10
        }&start=${start}&type=${
          type || "bulbFault"
        }&dataSourceId=${dataSourceId}`,
        method: "GET",
      }),
    }),
    getBulbNotificationCount: builder.query({
      query: ({
        userId,
        dataSourceId,
      }: {
        userId: string;
        dataSourceId: string;
      }) => ({
        url: `notifications/historic/${userId}/count?dataSourceId=${dataSourceId}`,
        method: "GET",
      }),
    }),

    // APP SPECIFIC
    sendBugReport: builder.mutation({
      query: ({ data }: { data: { title: string; description: string } }) => ({
        url: `${apiHostname}/bug-reports`,
        method: "POST",
        body: data,
      }),
    }),

    // chat

    getChatAdmins: builder.query({
      query: () => ({
        url: `chat/admins`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetUserQuery,
  useCreateUserMutation,
  useCreateOrgMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useUploadProfilePictureMutation,
  useGetMembersQuery,
  useLazyGetMembersQuery,
  useChangeRoleMutation,

  useGetChatAdminsQuery,

  // Org
  useOrgJoinRequestMutation,
  useOrgJoinRequestAcceptMutation,
  useOrgJoinRequestRejectMutation,
  useGetOrgsQuery,
  useLazyGetOrgsQuery,
  useLeaveOrganisationMutation,
  useRemoveUserFromOrganisationMutation,
  useCheckBulbStatusInOrgQuery,

  // Homes
  useGetAllHomesQuery,
  useCreateHomeMutation,
  useUpdateHomeMutation,
  useDeleteHomeMutation,
  useGetHomesByOrgQuery,
  useLazyGetHomesByOrgQuery,

  // Rooms
  useCreateRoomMutation,
  useUpdateRoomMutation,
  useDeleteRoomMutation,

  // Bulbs

  useLazyGetBulbsbyHomeQuery,
  useLazyGetAllHomesQuery,
  useLazyGetBulbsQuery,
  useLazyGetBulbQuery,
  useGetAllBulbsQuery,
  useGetBulbQuery,
  useGetAllUnassignedBulbsQuery,
  useLazyGetAllUnassignedBulbsQuery,
  useGetBulbsbyRoomQuery,
  useGetBulbsbyHomeQuery,
  useCreateBulbMutation,
  useAssignBulbsMutation,
  useRemoveBulbMutation,
  useUpdateMasterBulbMutation,
  useUploadFirmwareMutation,
  useGetBulbLiveStatusQuery,
  useGetBulbDataWithRequestCountQuery,

  // DATA

  // notification
  useResolveNotificationMutation,
  useGetPaginatedNotificationsQuery,
  useLazyGetPaginatedNotificationsQuery,
  useGetPaginatedNotificationsByBulbIdQuery,
  useLazyGetPaginatedNotificationsByBulbIdQuery,
  useGetBulbNotificationCountQuery,
  useLazyGetBulbNotificationCountQuery,
  useUploadFCMTokenMutation,
  useDeleteFCMTokenMutation,

  // APP SPECIFIC
  useSendBugReportMutation,

  useGetLiveDataQuery,
} = api;
