import React, { useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetOrgsQuery, useGetHomesByOrgQuery, useGetAllBulbsQuery } from 'src/@rtk/queries';
import {
  Typography,
  CircularProgress,
  Box,
  Paper,
  Grid,
  useTheme,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,
} from '@mui/material';
import { Error, FilterList, Clear, Sort, Search, Refresh } from '@mui/icons-material';
import { Bulb } from 'src/interfaces/bulbs.interface';
import { BulbLiveView } from './views/bulb-live';
import BulbDetailsDialog from 'src/components/dialogs/bulb-view';
import moment from 'moment';

interface Organization {
  id: string;
  name: string;
}

const Bulbs: React.FC = () => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState<string>('');
  const [selectedHome, setSelectedHome] = useState<string>('');
  const [selectedRoom, setSelectedRoom] = useState<string>('');
  const [timeThreshold, setTimeThreshold] = useState<number>(120);
  const [pollingInterval, setPollingInterval] = useState<number>(60000);
  const [selectedBulb, setSelectedBulb] = useState<Bulb | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortedFaultyBulbs, setSortedFaultyBulbs] = useState<Bulb[]>([]);
  const [sortedNonFaultyBulbs, setSortedNonFaultyBulbs] = useState<Bulb[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const {
    data: orgs,
    isLoading: isOrgsLoading,
    error: orgsError,
  } = useGetOrgsQuery(undefined);

  const {
    data: homes,
    isLoading: isHomesLoading,
    error: homesError,
  } = useGetHomesByOrgQuery({
    orgId: selectedOrg
  }, {
    skip: !selectedOrg || selectedOrg === 'All'
  });

  const {
    data: bulbs,
    isLoading: isBulbsLoading,
    error: bulbsError,
    refetch: refetchBulbs
  } = useGetAllBulbsQuery(selectedOrg, {
    pollingInterval: pollingInterval,
    skipPollingIfUnfocused: true,
    refetchOnMountOrArgChange: true,
    skip: !selectedOrg || selectedOrg === 'All'
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const orgId = params.get('orgId');
    const homeId = params.get('homeId');
    const roomId = params.get('roomId');
    if (orgId) setSelectedOrg(orgId);
    if (homeId) setSelectedHome(homeId);
    if (roomId) setSelectedRoom(roomId);
    if (orgId || homeId || roomId) {
      // refetchBulbs();
    }
  }, [location]);

  const checkBulbState = (bulb: Bulb): Bulb => {
    const timestampSeconds = bulb.lastCommunicationTimestamp;
    if (timestampSeconds) {
      const currentTime = moment();
      const lastActive = moment.unix(timestampSeconds);
      const diff = currentTime.diff(lastActive, 'seconds');
      return {
        ...bulb,
        isFaulty: diff > timeThreshold,
      };
    } else {
      return {
        ...bulb,
        isFaulty: true,
      };
    }
  }

  function sortHomeStreet(bulbs: Bulb[]): Bulb[] {
    // Filter out bulbs without homeId, roomId, or orgId
    const validBulbs = bulbs.filter(bulb => bulb.homeId && bulb.roomId && bulb.orgId && bulb.homeLabel);

    const invalidBulbs = bulbs.filter(bulb => !bulb.homeId || !bulb.roomId || !bulb.orgId || !bulb.homeLabel);
    console.log('Invalid Bulbs:', invalidBulbs);

    // Extract unique streets in the order they appear
    const uniqueStreets = Array.from(new Set(
      validBulbs.map(bulb => bulb.homeLabel?.split(/\d+\s+/).pop() || bulb.homeLabel)
    ));

    // Sort streets alphabetically
    const sortedStreets = uniqueStreets.sort((a, b) => a.localeCompare(b));

    // Group bulbs by street
    const groupedBulbs = validBulbs.reduce((acc, bulb) => {
      const street = bulb.homeLabel?.split(/\d+\s+/).pop() || bulb.homeLabel;
      if (!acc[street]) {
        acc[street] = [];
      }
      const number = parseInt(bulb.homeLabel?.match(/^\d+/)?.[0] || '0');
      acc[street].push({ number, bulb });
      return acc;
    }, {} as Record<string, { number: number; bulb: Bulb }[]>);

    // Sort bulbs within each street group by door number
    for (const street in groupedBulbs) {
      groupedBulbs[street].sort((a, b) => a.number - b.number);
    }

    // Flatten the grouped bulbs back into a single array, following the sorted street order
    const sortedBulbs = sortedStreets.flatMap(street =>
      groupedBulbs[street] ? groupedBulbs[street].map(item => item.bulb) : []
    );

    return sortedBulbs;
  }

  useEffect(() => {
    if (bulbs?.data) {
      setIsLoading(true);
      const filtered = bulbs?.data.filter((bulb: Bulb) => {
        if (selectedOrg && bulb.orgId !== selectedOrg) return false;
        if (selectedHome) {
          const home = homes?.data?.find((home: any) => home?.id === selectedHome);
          if (!home?.rooms?.some((room: any) => room?.id === bulb?.roomId)) return false;
        }
        if (selectedRoom && bulb?.roomId !== selectedRoom) return false;
        if (searchTerm) {
          const search = searchTerm.toLowerCase();
          return (
            bulb.homeLabel?.toLowerCase().includes(search) ||
            bulb.roomLabel?.toLowerCase().includes(search)
          );
        }
        return true;
      }).map(checkBulbState);



      // Separate faulty and non-faulty bulbs
      const faultyBulbs = filtered.filter((bulb: Bulb) => bulb.isFaulty);
      const nonFaultyBulbs = filtered.filter((bulb: Bulb) => !bulb.isFaulty);

      const sortedFaulty = sortHomeStreet(faultyBulbs);
      const sortedNonFaulty = sortHomeStreet(nonFaultyBulbs);

      setSortedFaultyBulbs(sortedFaulty);
      setSortedNonFaultyBulbs(sortedNonFaulty);
      setIsLoading(false);
    }
  }, [bulbs, selectedOrg, selectedHome, selectedRoom, searchTerm, homes?.data, timeThreshold]);

  const handleOpenFilter = () => setOpenFilter(true);
  const handleCloseFilter = () => setOpenFilter(false);

  const handleOrgChange = (event: any) => {
    const newOrgId = event.target.value as string;
    setSelectedOrg(newOrgId);
    setSelectedHome('');
    setSelectedRoom('');
    applyFilter({ orgId: newOrgId });
  };

  const handleHomeChange = (event: any) => {
    const newHomeId = event.target.value as string;
    setSelectedHome(newHomeId);
    setSelectedRoom('');
    applyFilter({ orgId: selectedOrg, homeId: newHomeId });
  };

  const handleRoomChange = (event: any) => {
    const newRoomId = event.target.value as string;
    setSelectedRoom(newRoomId);
    applyFilter({ orgId: selectedOrg, homeId: selectedHome, roomId: newRoomId });
  };

  const applyFilter = (filters: { orgId?: string; homeId?: string; roomId?: string; threshold?: number }) => {
    const params = new URLSearchParams();
    if (filters.orgId) params.set('orgId', filters.orgId);
    if (filters.homeId) params.set('homeId', filters.homeId);
    if (filters.roomId) params.set('roomId', filters.roomId);
    if (filters.threshold) params.set('threshold', filters.threshold.toString());
    navigate({ search: params.toString() });
  };

  const clearFilters = () => {
    setSelectedOrg('');
    setSelectedHome('');
    setSelectedRoom('');
    setSearchTerm('');
    handleCloseFilter();
    navigate({ search: '' });
  };

  const handleTimeThresholdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newThreshold = parseInt(event.target.value, 10);
    if (!isNaN(newThreshold) && newThreshold > 0) {
      setTimeThreshold(newThreshold);
      applyFilter({ orgId: selectedOrg, homeId: selectedHome, roomId: selectedRoom, threshold: newThreshold });
    }
  };

  const toggleSortOrder = () => {
    // setSortOrder(prevOrder => prevOrder === 'asc' ? 'desc' : 'asc');
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSelectBulb = (bulb: Bulb) => {
    setSelectedBulb(bulb);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (orgsError || bulbsError) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Paper elevation={3} sx={{ p: 3, textAlign: 'center' }}>
          <Error color="error" sx={{ fontSize: 60, mb: 2 }} />
          <Typography variant="h6" color="error">
            Error loading data
          </Typography>
        </Paper>
      </Box>
    );
  }


  const displayBulbs = sortOrder === 'asc'
    ? [...sortedFaultyBulbs, ...sortedNonFaultyBulbs]
    : [...sortedFaultyBulbs.reverse(), ...sortedNonFaultyBulbs.reverse()];

  return (
    <Box
      sx={{
        p: 3,
        height: `calc(100vh - ${theme.header.height})`,
        overflowY: 'auto',
        pb: 10
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Box display={'flex'} alignItems={'center'}>
          <Typography variant="h4">Bulbs Overview</Typography>
          <IconButton
            onClick={() => refetchBulbs()}
            title="Refresh Bulbs"
            sx={{ ml: 2 }}
          >
            <Refresh />
          </IconButton>
        </Box>

        <Box display="flex" alignItems="center">
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search home or room"
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: <Search />,
            }}
            sx={{ mr: 2 }}
          />
          <Button
            variant="contained"
            startIcon={<FilterList />}
            onClick={handleOpenFilter}
          >
            Filter
          </Button>
        </Box>
      </Box>

      <Dialog
        sx={{
          '& .MuiDialog-paper': {
            minWidth: 400,
            [theme.breakpoints.down('sm')]: {
              minWidth: '100%',
            },
          },
        }}
        open={openFilter} onClose={handleCloseFilter}>
        <DialogTitle>Filter Bulbs</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 2 }}>

            <TextField
              label="Polling Interval (seconds)"
              type="number"
              value={pollingInterval / 1000}
              defaultValue={
                pollingInterval / 1000
              }
              onChange={(e) => setPollingInterval(parseInt(e.target.value) * 1000)}
              inputProps={{ min: 1 }}
            />
          </FormControl>

          <FormControl fullWidth sx={{ mt: 2 }}>
            <TextField
              label="Time Threshold (seconds)"
              type="number"
              value={timeThreshold}
              onChange={handleTimeThresholdChange}
              inputProps={{ min: 1 }}
            />

          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="org-select-label">Select Organization</InputLabel>
            <Select
              labelId="org-select-label"
              value={selectedOrg}
              label="Select Organization"
              onChange={handleOrgChange}
            >
              <MenuItem value="All">All</MenuItem>
              {orgs?.data?.map((org: Organization) => (
                <MenuItem key={org.id} value={org.id}>{org.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="home-select-label">Select Home</InputLabel>
            <Select
              labelId="home-select-label"
              value={selectedHome}
              label="Select Home"
              onChange={handleHomeChange}
              disabled={!selectedOrg}
            >
              {homes?.data?.map((home: any) => (
                <MenuItem key={home.id} value={home.id}>{home.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="room-select-label">Select Room</InputLabel>
            <Select
              labelId="room-select-label"
              value={selectedRoom}
              label="Select Room"
              onChange={handleRoomChange}
              disabled={!selectedHome}
            >
              {homes?.data?.find((home: any) => home.id === selectedHome)?.rooms.map((room: any) => (
                <MenuItem key={room.id} value={room.id}>{room.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={clearFilters} startIcon={<Clear />}>
            Clear Filters
          </Button>
          <Button onClick={handleCloseFilter}>Close</Button>
        </DialogActions>
      </Dialog>

      <Grid container spacing={0} gap={1}>
        {displayBulbs.map((bulb: Bulb) => (
          <Grid item xs={12} sm={6} md={1} lg={1} key={bulb.id}>
            <BulbLiveView
              bulb={bulb}
              timeThreshold={timeThreshold}
              onSelectBulb={handleSelectBulb}
            />
          </Grid>
        ))}
      </Grid>
      <BulbDetailsDialog
        open={selectedBulb !== null}
        onClose={() => setSelectedBulb(null)}
        bulbData={selectedBulb}
      />
    </Box>
  );
};

export default Bulbs;